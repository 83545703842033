import React from 'react'
import SEO from 'utils/seo'
import { Link } from 'gatsby'
import {
  Container,
  Title,
  Button,
  Content
} from 'components/atoms'
import {
  Layout
} from 'components/organisms'
import styled from 'styled-components'
import { useLang } from 'hooks'
import { FormattedMessage } from 'react-intl'

const NotFound = props => {
  const { langKey } = useLang(props.location)
  const homeLink = `/${ langKey !== 'pt' ? langKey : '' }`

  return (
    <Layout inverseTheme={true} location={props.location}>
      <SEO title="404" />
      <Container modifiers={['fixNav']}>
        <Center>
          <Title modifiers={['ml', 'weightMedium']} withSeperator>
            <FormattedMessage id="404.title" />
          </Title>
          <Content>
            <Link to={homeLink}>
              <Button>
                <FormattedMessage id="404.back" />
              </Button>
            </Link>
          </Content>
        </Center>
      </Container>

    </Layout>
  )
}

const Center = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

export default NotFound
